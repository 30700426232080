import React from 'react';
import classNames from 'classnames';
import fp from 'lodash';
import Fuse from 'fuse.js';
import { motion } from 'framer-motion';

const fuseOptions = {
    // isCaseSensitive: false,
    includeScore: true,
    // shouldSort: true,
    includeMatches: true,
    // findAllMatches: false,
    // minMatchCharLength: 1,
    // location: 0,
    // threshold: 0.6,
    // distance: 100,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    keys: ['route_short_name', 'route_long_name', 'agency'],
};

export class VehicleSidebarList extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (
            fp.isNil(this.props.routesData) ||
            fp.isEmpty(this.props.routesData)
        ) {
            return (
                <div className="py-3 lh-tight text-center">
                    <p className="w-100 align-items-center justify-content-between">
                        <strong className="mb-1">No data</strong>
                    </p>
                    <p className="mb-1 small">
                        Please enable at least 1 dataset.
                    </p>
                </div>
            );
        }

        // render for no search
        if (fp.isEmpty(this.props.filter.search)) {
            return (
                <div className="list-group list-group-flush border-bottom scrollarea">
                    {this.props.routesData.map((route, index) => {
                        const refIndex = fp.isNil(route.refIndex)
                            ? index
                            : route.refIndex;
                        return (
                            <motion.button
                                initial={{ x: -200 }}
                                animate={{ x: 0 }}
                                transition={{ type: 'spring', duration: 0.5 }}
                                key={refIndex}
                                className={`list-group-item list-group-item-action py-3 lh-tight opacity-100 ${
                                    this.props.focus === refIndex
                                        ? 'active'
                                        : ''
                                }`}
                                onClick={() => this.props.handleClick(refIndex)}
                                disabled={route.count == 0}
                            >
                                <div className="my-2 d-flex w-100 align-items-center justify-content-between">
                                    <h4>
                                        {route.route_short_name}{' '}
                                        {route.route_long_name}
                                    </h4>
                                </div>
                                <div className="my-2 d-flex w-100 align-items-center justify-content-between">
                                    <div className="my-2 small">
                                        {route.agency}
                                    </div>
                                    <div
                                        className={classNames(
                                            'ms-auto badge rounded-pill',
                                            {
                                                'bg-primary': route.count != 0,
                                                'bg-secondary':
                                                    route.count == 0,
                                            }
                                        )}
                                    >
                                        {route.has_realtime
                                            ? `${route.count}`
                                            : 'No realtime data'}{' '}
                                        available
                                    </div>
                                </div>
                            </motion.button>
                        );
                    })}
                </div>
            );
        }

        const fuse = new Fuse(this.props.routesData, fuseOptions);

        const result = fuse
            .search(this.props.filter.search)
            .map((e) => ({ ...e.item, refIndex: e.refIndex }));

        // render where nothing is found
        if (result.length == 0) {
            return (
                <motion.div
                    initial={{ x: 0 }}
                    animate={{
                        x: [-2, 2, -1, 1, 0],
                    }}
                    transition={{ type: 'spring', duration: 0.5 }}
                    className="py-3 lh-tight text-center"
                >
                    <p className="w-100 mx-auto align-items-center justify-content-between">
                        <strong className="mb-1">No result</strong>
                    </p>
                    <p className="w-100 mx-auto mb-1 small">
                        No data fit the criteria.
                    </p>
                </motion.div>
            );
        }

        // render for search result
        return (
            <div className="list-group list-group-flush border-bottom scrollarea">
                {result.map((route, index) => {
                    const refIndex = fp.isNil(route.refIndex)
                        ? index
                        : route.refIndex;
                    return (
                        <motion.button
                            initial={{ x: -200 }}
                            animate={{ x: 0 }}
                            transition={{ type: 'spring', duration: 0.5 }}
                            key={refIndex}
                            className={`list-group-item list-group-item-action py-3 lh-tight opacity-100 ${
                                this.props.focus === refIndex ? 'active' : ''
                            }`}
                            onClick={() => this.props.handleClick(refIndex)}
                            disabled={route.count == 0}
                        >
                            <div className="my-2 d-flex w-100 align-items-center justify-content-between">
                                <h4>
                                    {route.route_short_name}{' '}
                                    {route.route_long_name}
                                </h4>
                            </div>
                            <div className="my-2 d-flex w-100 align-items-center justify-content-between">
                                <div className="my-2 small">{route.agency}</div>
                                <div
                                    className={classNames(
                                        'ms-auto badge rounded-pill',
                                        {
                                            'bg-primary': route.count != 0,
                                            'bg-secondary': route.count == 0,
                                        }
                                    )}
                                >
                                    {route.has_realtime
                                        ? `${route.count}`
                                        : 'No realtime data'}{' '}
                                    available
                                </div>
                            </div>
                        </motion.button>
                    );
                })}
            </div>
        );
    }
}
