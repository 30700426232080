import React from 'react';
import { motion } from 'framer-motion';

export default function TripCard({ route, handleClick }) {
    return (
        <motion.button
            initial={{ y: 220 }}
            animate={{ y: 0 }}
            transition={{ type: 'spring', duration: 0.5 }}
            className="trip-card list-group-item list-group-item-action py-2 lh-tight"
            onClick={() => handleClick(route)}
        >
            <div className="my-2 d-flex w-100 align-items-center justify-content-around">
                <p
                    className="head-sign flex-grow-1 text-center"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Trip headsign of the vehicle"
                >
                    {route.trip.trip_headsign}
                </p>
                <p
                    className="stop flex-grow-1 text-center"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Name of the next stop"
                >
                    {route.stop.stop_name}
                </p>
                <p
                    className="vehicle-label flex-grow-1 text-center"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Vehicle label"
                >
                    {route.vehicle.vehicle.label}
                </p>
            </div>
        </motion.button>
    );
}
