import logo from './logo.svg';
import './App.css';
import 'mapbox-gl/dist/mapbox-gl.css';

import fp from 'lodash';
import React, { useRef, useEffect, useState } from 'react';
import classNames from 'classnames';

import Dashboard from './components/Dashboard';
import { VehicleSidebarList } from './components/VehicleSidebarList';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY;

import TripCard from './components/TripCard';
import { BiChevronsDown } from 'react-icons/bi';
import { MdGpsFixed } from 'react-icons/md';
import { AiFillSetting } from 'react-icons/ai';

export default function App() {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(0);
    const [lat, setLat] = useState(0);
    const [zoom, setZoom] = useState(5);
    const [isOverlayOn, setIsOverlayOn] = useState(true);
    const [selfLocation, setSelfLocation] = useState({});
    const [realtimeData, setRealtimeData] = useState({});
    const [staticData, setStaticData] = useState({});
    const [datasets, setDatasets] = useState([]);
    const [focus, setFocus] = useState(-1);
    const [filter, setFilter] = useState({ search: '' });
    const [routesData, setRoutesData] = useState([]);

    // fetching data
    useEffect(() => {
        fetch('/api/static/dataset')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not OK');
                }
                return response.json();
            })
            .then((data) => {
                setDatasets(
                    data
                        .filter((dataset) => dataset.is_enabled)
                        .map((dataset) => {
                            dataset.status = 0;
                            return dataset;
                        })
                );
            })
            .catch((error) => {
                console.error('Fetch operation errors:', error);
            });
    }, []);

    // init Mapbox
    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11?optimize=true',
            center: [lng, lat],
            zoom: zoom,
            minZoom: 5,
            bearing: 0,
            logoPosition: 'bottom-right',
            dragRotate: false,
            pitchWithRotate: false,
            touchPitch: false,
            touchZoomRotate: false,
        });
        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });

        map.current.on('load', () => {
            map.current.loadImage('bus-custom.png', (error, image) => {
                if (error) {
                    throw error;
                }
                map.current.addImage('bus-custom', image);
            });
        });
    }, []);

    const addMapLayer = (map, name, realtimeData) => {
        map.addSource(`realtimeData${fp.camelCase(name)}`, {
            type: 'geojson',
            data: createMapDataSource(realtimeData, name),
        });
        // Add a symbol layer
        map.addLayer({
            id: `realtimeData${fp.camelCase(name)}`,
            type: 'symbol',
            source: `realtimeData${fp.camelCase(name)}`,
            layout: {
                'icon-image': 'bus-custom',
                'icon-rotate': ['get', 'rotate'],
                'icon-size': 0.5,
                // 'icon-text-fit': 'both',
                // get the title name from the source's "title" property
                'text-field': ['get', 'title'],
                'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                'icon-rotation-alignment': 'map',
            },
        });
    };

    const removeMapLayer = (map, name) => {
        map.removeLayer(`realtimeData${fp.camelCase(name)}`);
        map.removeSource(`realtimeData${fp.camelCase(name)}`);
    };

    // generate the markers
    const createMapDataSource = (realtimeData, name) => {
        let featureList = fp.isNil(realtimeData.vehiclePositions.entity)
            ? []
            : realtimeData.vehiclePositions.entity.map((entity) => ({
                  type: 'Feature',
                  geometry: {
                      type: 'Point',
                      coordinates: [
                          entity.vehicle.position.longitude,
                          entity.vehicle.position.latitude,
                      ],
                  },
                  properties: {
                      title: entity.vehicle.vehicle.label,
                      description: entity.vehicle.trip.routeId,
                      rotate:
                          parseFloat(entity.vehicle.position.bearing) - 90.0,
                  },
              }));

        return {
            type: 'FeatureCollection',
            features: featureList,
        };
    };

    const initData = async (dataset) => {
        const realtimeFiles = [
            'service-alerts',
            'trip-updates',
            'vehicle-positions',
        ];

        const staticFiles = ['agency', 'routes', 'stops', 'trips'];

        const requestStatic = (file) =>
            fetch(`/api/static/${dataset.name}/${file}`)
                .then((response) =>
                    response.ok
                        ? Promise.resolve(response)
                        : Promise.reject(new Error(response.statusText))
                )
                .then((response) =>
                    Promise.all([
                        Promise.resolve(fp.camelCase(file)),
                        response.json(),
                    ])
                );

        const requestRealtime = (file) =>
            fetch(`/api/realtime/${dataset.name}/${file}`)
                .then((response) =>
                    response.ok
                        ? Promise.resolve(response)
                        : Promise.reject(new Error(response.statusText))
                )
                .then((response) =>
                    Promise.all([
                        Promise.resolve(fp.camelCase(file)),
                        response.json(),
                    ])
                );

        try {
            const [staticDataResponse, realtimeDataResponse] =
                await Promise.allSettled([
                    Promise.allSettled(staticFiles.map(requestStatic)),
                    Promise.allSettled(realtimeFiles.map(requestRealtime)),
                ]);
            let newStaticDataset = {
                [dataset.name]: {},
            };

            let newRealtimeDataset = {
                [dataset.name]: {},
            };

            for (const e of staticDataResponse.value) {
                newStaticDataset[dataset.name][e.value[0]] = e.value[1];
            }

            for (const e of realtimeDataResponse.value) {
                newRealtimeDataset[dataset.name][e.value[0]] = e.value[1];
            }

            setStaticData(Object.assign(staticData, newStaticDataset));
            setRealtimeData(Object.assign(realtimeData, newRealtimeDataset));
            setRoutesData(
                routesData.concat(
                    updateRoutes(newStaticDataset, newRealtimeDataset)
                )
            );
            setDatasets(
                datasets.map((e) => {
                    if (e.dataset_id == dataset.dataset_id) {
                        e.status = 1;
                    }
                    return e;
                })
            );
            addMapLayer(
                map.current,
                dataset.name,
                newRealtimeDataset[dataset.name]
            );
        } catch (e) {
            console.log(e);
        }
    };

    const deleteDataset = (dataset) => {
        let newStaticDataset = { ...state.staticData };
        delete newStaticDataset[dataset.name];
        let newRealtimeDataset = { ...state.realtimeData };
        delete newRealtimeDataset[dataset.name];
        setRealtimeData(newRealtimeDataset);
        setFocus(-1);
        setStaticData(newStaticDataset);
        removeMapLayer(map.current, dataset.name);
    };

    const handleSelfGps = (e) => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const selfMarker = new mapboxgl.Marker()
                    .setLngLat([
                        position.coords.longitude,
                        position.coords.latitude,
                    ])
                    .addTo(map.current);
                setSelfLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                    marker: selfMarker,
                });
                map.current.flyTo({
                    center: [
                        position.coords.longitude,
                        position.coords.latitude,
                    ],
                    zoom: 14,
                });
            },
            (error) =>
                alert(`
            Error Code: ${error.code}
            Message: ${error.message}`),
            {
                enableHighAccuracy: true,
                timeout: 10000,
            }
        );
    };

    const handleApply = async (dataset, event) => {
        setDatasets(
            fp.cloneDeep(datasets).map((e) => {
                if (e.dataset_id === dataset.dataset_id) {
                    e.status = -1;
                }
                return e;
            })
        );
        await initData(dataset);
        setFilter({ ...filter, search: '' });
    };

    const updateRoutes = (newStaticData, newRealtimeData) => {
        let routes = [];

        const findStop = (route, name) => {
            return staticData[name].stops.find(
                (e) => e.stop_id === route.vehicle.stopId
            );
        };
        const findTrip = (route, name) => {
            return staticData[name].trips.find(
                (e) => e.trip_id === route.vehicle.trip.tripId
            );
        };

        for (const [agency, data] of Object.entries(newStaticData)) {
            routes = [
                ...routes,
                ...data.routes.map((route) => {
                    route.count = 0;
                    route.agency = agency;
                    route.routes = [];
                    return route;
                }),
            ];
        }

        for (const [agency, data] of Object.entries(newRealtimeData)) {
            if (
                !fp.isNil(data.vehiclePositions.entity) &&
                !fp.isEmpty(data.vehiclePositions.entity)
            ) {
                for (const entity of data.vehiclePositions.entity) {
                    const index = routes.findIndex((e) => {
                        return (
                            e.agency === agency &&
                            e.route_id === entity.vehicle.trip.routeId
                        );
                    });
                    routes[index].count++;
                    routes[index].routes = [
                        ...routes[index].routes,
                        ...[
                            {
                                stop: findStop(entity, routes[index].agency),
                                trip: findTrip(entity, routes[index].agency),
                                vehicle: entity.vehicle,
                            },
                        ],
                    ];
                }
            }
        }
        return routes;
    };

    return (
        <div id="app">
            {isOverlayOn && (
                <div id="overlay">
                    <div className="d-flex flex-column p-md-2 min-vh-100">
                        <header className="App-header text-center my-5 fs-1">
                            <h1>
                                <strong>
                                    REALTIME{' '}
                                    <img src={logo} id="logo" alt="logo" />{' '}
                                    TRANSITS
                                </strong>
                            </h1>
                        </header>
                        <section id={'dataset-list'}>
                            <Dashboard
                                datasets={datasets}
                                handleApply={(datasets, event) =>
                                    handleApply(datasets, event)
                                }
                                handleGPS={(lng, lat, event) => {
                                    map.current.flyTo({
                                        center: [lng, lat],
                                        zoom: 14,
                                    });
                                }}
                            />
                        </section>
                        <footer className="mt-auto py-3">
                            <p className="text-center">
                                Copyright © 2019-2023 Daniel Cao
                            </p>
                        </footer>
                    </div>
                </div>
            )}

            <section
                id="vehicle-list"
                className="d-flex flex-column align-items-stretch bg-white col-2"
            >
                <div className="align-items-center flex-shrink-0 p-3 link-dark text-decoration-none border-bottom">
                    <h3 className="visually-hidden">Available routes</h3>
                    <label htmlFor="searchBar" className="visually-hidden">
                        Search routes
                    </label>
                    <input
                        id="searchBar"
                        name="search"
                        className="form-control form-control-lg"
                        type="text"
                        value={filter.search}
                        onChange={(e) => {
                            setFilter({
                                ...filter,
                                search: e.target.value,
                            });
                        }}
                        placeholder="Enter search..."
                        aria-label="search"
                    />
                </div>
                <VehicleSidebarList
                    routesData={routesData}
                    filter={filter}
                    focus={focus}
                    handleClick={(index, event) => {
                        setFocus(index);
                    }}
                />
            </section>

            {focus >= 0 &&
                !fp.isNil(routesData[focus]) &&
                !fp.isEmpty(routesData[focus]) && (
                    <section
                        id="focus-panel"
                        className="d-flex flex-column justify-content-end align-items-stretch bg-white"
                    >
                        <button
                            type="button"
                            className="btn text-primary bg-white"
                            onClick={() => setFocus(-1)}
                        >
                            <BiChevronsDown />
                        </button>
                        <div className="list-group list-group-flush scrollarea">
                            {routesData[focus].routes.map((route) => (
                                <TripCard
                                    key={route.vehicle.trip.tripId}
                                    route={route}
                                    handleClick={(route) => {
                                        map.current.flyTo({
                                            center: [
                                                route.vehicle.position
                                                    .longitude,
                                                route.vehicle.position.latitude,
                                            ],
                                            zoom: 17,
                                        });
                                    }}
                                />
                            ))}
                        </div>
                    </section>
                )}
            <div
                id="ui-buttons"
                className="col-3 col-md-2 p-3 d-flex flex-column-reverse justify-content-end"
            >
                <button
                    type="button"
                    className={classNames('btn btn-lg m-3 align-self-end', {
                        'btn-primary opacity-75': !isOverlayOn,
                        'btn-outline-dark text-light border-light border-3':
                            isOverlayOn,
                    })}
                    onClick={() => handleSelfGps()}
                >
                    <MdGpsFixed />
                </button>

                <button
                    type="button"
                    className={classNames('btn btn-lg m-3 align-self-end', {
                        'btn-primary opacity-75': !isOverlayOn,
                        'btn-outline-dark text-light border-light border-3':
                            isOverlayOn,
                    })}
                    onClick={() => setIsOverlayOn(!isOverlayOn)}
                >
                    <AiFillSetting />
                </button>
            </div>

            <main>
                <section>
                    <h2 className="visually-hidden">Transit Map</h2>
                    <div ref={mapContainer} className="map-container" />
                </section>
            </main>
        </div>
    );
}
