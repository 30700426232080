import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import fp from 'lodash';
import Select from 'react-select';
import { FaLocationArrow } from 'react-icons/fa';
import { HiDownload } from 'react-icons/hi';
import { RiCheckLine } from 'react-icons/ri';
import { MdOutlineUpdate, MdOutlineUpdateDisabled } from 'react-icons/md';

export default function Dashboard(props) {
    //datasets, handleApply, handleGPS
    const [isLoading, setIsLoading] = useState(false);

    useEffect(
        () => setIsLoading(props.datasets.some((e) => e.status === -1)),
        [props.datasets]
    );

    return (
        <div>
            <div className="d-flex flex-column justify-content-evenly align-items-center align-self-center my-4">
                <h2 className="col-md-10 col-xl-8 col-xl-6 my-4 fs-3">
                    Data selection
                </h2>
                <div className="text-center">
                    <p>Please enable the data you want to see!</p>
                </div>
            </div>

            {/*to add select*/}
            {/*<div className={'row'}>*/}
            {/*    <div className={'col-6 mx-auto'}>*/}
            {/*        <div className={'row'}>*/}
            {/*            <div className={'col-8'}>*/}
            {/*                <Select*/}
            {/*                    placeholder={'Select a dataset to display'}*/}
            {/*                    className="basic-single"*/}
            {/*                    classNamePrefix="select"*/}
            {/*                    isDisabled={!props.datasets.length}*/}
            {/*                    isLoading={false}*/}
            {/*                    isClearable={true}*/}
            {/*                    isSearchable={true}*/}
            {/*                    autoFocus={true}*/}
            {/*                    name="dataset"*/}
            {/*                    options={*/}
            {/*                        !props.datasets.length*/}
            {/*                            ? []*/}
            {/*                            : props.datasets.map((data) => {*/}
            {/*                                  data.value = data.dataset_id;*/}
            {/*                                  data.label = fp.capitalize(*/}
            {/*                                      data.name*/}
            {/*                                  );*/}
            {/*                                  data.isDisabled = data.status;*/}
            {/*                                  return data;*/}
            {/*                              })*/}
            {/*                    }*/}
            {/*                    onChange={(value) => {*/}
            {/*                        if (!fp.isNil(value)) {*/}
            {/*                            setSelecting(value.dataset_id);*/}
            {/*                        }*/}
            {/*                    }}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*            <div className={'col-4'}>*/}
            {/*                <button*/}
            {/*                    type="button"*/}
            {/*                    className="btn btn-lg btn-danger"*/}
            {/*                    onClick={(event) =>*/}
            {/*                        props.handleApply(selecting, event)*/}
            {/*                    }*/}
            {/*                >*/}
            {/*                    Add Location*/}
            {/*                </button>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className={'row'}>
                {props.datasets.length &&
                    props.datasets.map((dataset) => (
                        <div
                            key={dataset.dataset_id}
                            className="col-12 col-md-10 col-lg-7 my-4 mx-auto"
                        >
                            <div className="d-flex justify-content-between justify-content-lg-evenly">
                                <h4 className="text-capitalize flex-grow-1 align-self-center">
                                    <strong>{dataset.name}</strong>
                                </h4>

                                <LoadButton
                                    dataset={dataset}
                                    handleApply={props.handleApply}
                                    isLoading={isLoading}
                                />
                                <button
                                    type="button"
                                    className="btn btn-lg text-light align-self-end border-light btn-outline-dark border-3"
                                    onClick={(event) =>
                                        props.handleGPS(
                                            dataset.longitude,
                                            dataset.latitude,
                                            event
                                        )
                                    }
                                >
                                    <FaLocationArrow />
                                </button>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
}

const LoadButton = (props) => {
    let buttonIcon, style;
    switch (props.dataset.status) {
        case 1:
            style =
                'btn btn-lg align-self-end mx-5 btn-success opacity-100 position-relative';
            buttonIcon = <RiCheckLine />;
            break;
        case -1:
            style =
                'btn btn-lg align-self-end mx-5 btn-outline-dark text-light border-light border-3 position-relative';
            buttonIcon = (
                <React.Fragment>
                    <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="visually-hidden">Loading...</span>
                </React.Fragment>
            );
            break;
        default:
            style =
                'btn btn-lg align-self-end mx-5 btn-outline-dark text-light border-light border-3 position-relative';
            buttonIcon = <HiDownload />;
    }

    return (
        <button
            type="button"
            className={style}
            onClick={(event) => props.handleApply(props.dataset, event)}
            disabled={props.dataset.status !== 0 || props.isLoading}
        >
            {buttonIcon}
            {props.dataset.has_realtime ? (
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">
                    <MdOutlineUpdate />
                    <span className="visually-hidden">
                        Realtime availability
                    </span>
                </span>
            ) : (
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary">
                    <MdOutlineUpdateDisabled />
                    <span className="visually-hidden">
                        Realtime availability
                    </span>
                </span>
            )}
        </button>
    );
};
